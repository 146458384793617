import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

import BlogNav from "../../components/blog/nav/nav"
import Pager from "../../components/blog/pager/pager"
import Card from "../../components/blog/card/card"

import "./blog_archive.scss"

export const data = graphql`
  query($skip: Int!, $limit: Int!) {
    allDibPosts(
      sort: { order: DESC, fields: publishedAt }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          summary
          slug
          publishedAt
          readtime
          featuredImage
          categories {
            title
            slug
          }
          author {
            name
            photo
            slug
          }
        }
      }
    }
  }
`

const ArchivePage = ({ data, pageContext }) => {

  const posts = data.allDibPosts.edges

  return (
    <Layout>
      <Seo title="Blog Archive" />
      <div className="container mt-4">
          <div className="blog-header">
            <h2>Archive</h2>
            <BlogNav />
          </div>
          <div className="row">
            {posts.map((post, index) => (
              <div key={index} className="col-sm-6 col-md-4 mb-2">
                <Card post={post.node} tab={post.node.categories[0]} />
              </div>
            ))}
          </div>
          <Pager pageContext={pageContext} />
        </div>
    </Layout>
  )
}

export default ArchivePage
